import { graphql } from "gatsby"
import React from "react"
import { Heading, Text } from "rebass"
import Gallery from "../components/gallery"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const {
    albums: { edges },
  } = data
  const {
    node: { images },
  } = edges[0]

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Restaurant page" />
      <Heading p={[3, 4]} fontSize={[4, 5]} color="warning">
        Living Room Restaurant
      </Heading>
      <Text p={[3, 4]} fontSize={[2, 3]}>
        Sigal opens her living room for couples and groups limited to 6 people.
        <br />
        <br />
        What began as an evening of Friday night's dinner with friends and
        relatives became a place of gathering. wonderful atmosphere, excellent
        food and a loving art of cooking. Sigal became a close friend to those
        who were invited.
        <br />
        <br />
        The domestic atmosphere, food, art and conversations about topics from
        all over the world. Prices: 15 euros to anyone ( main course and
        dessert) including wine, beer, coffee, and tea.
      </Text>
      {images && <Gallery images={images} />}
    </Layout>
  )
}

export const restaurantPageQuery = graphql`
  query Restaurant {
    site {
      siteMetadata {
        title
      }
    }
    albums: allContentfulAlbum(filter: { name: { eq: "dinner" } }) {
      edges {
        node {
          images {
            fluid(maxWidth: 950) {
              aspectRatio
              sizes
              src
              srcSet
              srcWebp
              srcSetWebp
              tracedSVG
            }
          }
        }
      }
    }
  }
`
